export const timeAgo = (date) => {
  const seconds = Math.floor((new Date() - date) / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const weeks = Math.floor(days / 7);
  const months = Math.floor(days / 30);

  if (seconds < 60) {
    return seconds + " seconds ago";
  }

  if (minutes < 60) {
    return minutes === 1 ? "1 minute ago" : minutes + " minutes ago";
  }

  if (hours < 24) {
    return hours === 1 ? "1 hour ago" : hours + " hours ago";
  }

  if (days < 7) {
    return days === 1 ? "1 day ago" : days + " days ago";
  }

  if (weeks < 4 || months === 0) {
    return weeks === 1 ? "1 week ago" : weeks + " weeks ago";
  }

  if (months < 12) {
    return months === 1 ? "1 month ago" : months + " months ago";
  }

  const years = Math.floor(days / 365);
  return years === 1 ? "1 year ago" : years + " years ago";
};
