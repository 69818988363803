import { defineStore } from "pinia";

export const useSidebarStore = defineStore("sidebar", {
  state: () => ({
    expanded: false,
  }),
  actions: {
    isExpanded() {
      return this.expanded;
    },
    expand() {
      this.expanded = true;
    },
    hide() {
      this.expanded = false;
    },
  },
});
