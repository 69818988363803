<template>
  <div class="top-left-block">
    <button class="border-0 p-0" @click="hideSidebar">
      <span class="icon icon-menu">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M21 6H3V5H21V6ZM21 11H3V12H21V11ZM21 17H3V18H21V17Z"
            fill="black"
          />
        </svg>
      </span>
    </button>

    <router-link
      to="/"
      class="peertube-title c-hand"
      @click="hideSidebarOnMobile"
    >
      <img
        v-if="isDarkMode"
        :src="DarkModeLogo"
        class="instance-name logo-image"
        alt="light-mode-logo"
      />
      <img
        v-else
        :src="LightModeLogo"
        class="instance-name logo-image"
        alt="dark-mode-logo"
      />
    </router-link>
  </div>

  <div class="menu">
    <SideBarButton
      @linkClicked="hideSidebarOnMobile"
      label="Discover"
      :icon="discoverIcon"
      to="/"
    />

    <!-- <SideBarButton
        @linkClicked="hideSidebarOnMobile"
        label="Bursts"
        :icon="burstsIcon"
        to="/about"
        paths="['/about']"
      /> -->

    <SideBarButton
      @linkClicked="hideSidebarOnMobile"
      label="Trending"
      :icon="trendingIcon"
      to="/trending-videos"
    />

    <SideBarButton
      @linkClicked="hideSidebarOnMobile"
      label="Recently Added"
      :icon="recentlyAddedIcon"
      to="/recent-videos"
    />
  </div>

  <div class="menu flex-grow">
    <!-- <SideBarButton
        @linkClicked="hideSidebarOnMobile"
        label="About"
        :icon="aboutIcon"
        to="/about"
      /> -->

    <!-- <SideBarButton
        @linkClicked="hideSidebarOnMobile"
        label="Support"
        :icon="supportIcon"
        to="/team"
      /> -->
    <SideBarButton
      @linkClicked="hideSidebarOnMobile"
      label="Log In"
      :icon="loginIcon"
      :to="loginPath"
    />
  </div>

  <DarkModeSwitch />

  <div class="menu footer text-xs">
    <span class="material-icons"></span>

    <span class="text">
      <span class="flex flex-col gap-2 m-4">
        <span class="flex flex-row gap-4">
          <a href="https://help.parler.com/hc/90853520" target="_blank">Help</a>
        </span>
        <span class="text-gray-400">Powered by Parler &copy; 2024</span>
        <span class="text-gray-400">Version: {{ appVersion }}</span>
      </span>
    </span>
  </div>
</template>
<script setup>
import { computed } from "vue";
import { useSidebarStore } from "@/stores/sidebarStore.tsx";
import LightModeLogo from "@/assets/playtv/logo/logo-sm-light.svg";
import DarkModeLogo from "@/assets/playtv/logo/logo-sm-dark.svg";
import SideBarButton from "./SideBarButton.vue";
import discoverIcon from "@/assets/playtv/icons/sidebar/discover-icon.svg";
import loginIcon from "@/assets/playtv/icons/sidebar/login-icon.svg";
import recentlyAddedIcon from "@/assets/playtv/icons/sidebar/recently-added-icon.svg";
import trendingIcon from "@/assets/playtv/icons/sidebar/trending-icon.svg";
import { useRoute } from "vue-router";
import DarkModeSwitch from "@/components/sidebar/DarkModeSwitch.vue";
import { useDarkModeStore } from "@/stores/darkModeStore.tsx";

const sidebarStore = useSidebarStore();
const appVersion = import.meta.env.VITE_APP_VERSION;

const route = useRoute();

const loginPath = computed(() => {
  return `/login?redirect=${route.path}`;
});

const hideSidebar = () => {
  sidebarStore.hide();
};

const hideSidebarOnMobile = () => {
  const width = window.innerWidth;

  if (width < 1080) {
    sidebarStore.hide();
  }
};

const darkModeStore = useDarkModeStore();
const isDarkMode = computed(() => {
  return darkModeStore.darkMode;
});
</script>

<style lang="scss" scoped>
@import "../../assets/styles/mixins.scss";
@import "./css/sidebar.scss";

.dark-theme-toggle {
  padding: 0.5rem 1rem;
}
</style>
