<template>
  <div v-if="video" class="p-0 md:p-4 video-preview">
    <!-- Video Thumbnail -->
    <SensitivityOverlay
      :isSensitive="video?.isSensitive"
      :isWebOnly="video?.isWebOnly"
      :videoId="video?.id"
    >
      <span class="hidden"></span>
      <router-link :to="videoUrl">
        <div
          class="w-full aspect-w-16 aspect-h-9 relative flex justify-center items-center md:rounded-lg overflow-hidden"
          v-if="video.thumbnailUrl"
        >
          <img
            :src="video.thumbnailUrl"
            v-show="imgLoaded"
            alt="Video Thumbnail"
            class="h-full object-cover cursor-pointer md:rounded-lg"
            @load="imgLoaded = true"
          />
          <div
            v-show="!imgLoaded"
            class="absolute inset-0 flex items-center justify-center bg-zinc-300 dark:bg-zinc-800 animate-pulse"
          ></div>
          <div
            v-if="video.videoDuration > 0"
            class="flex items-center justify-center video-length-label"
          >
            <p class="text-white text-xs bg-black/75">
              {{ formattedDuration }}
            </p>
          </div>
          <!-- Progress Bar for Last Watched Time -->

          <div
            v-if="watchedPercentage > 0"
            class="h-[0.25rem] bg-red-600 z-10 absolute top-[98%] left-0"
            :style="{ width: watchedPercentage + '%' }"
          ></div>
        </div>
        <div
          v-else
          class="w-full aspect-w-16 aspect-h-9 flex items-center justify-center bg-gray-300 dark:bg-gray-800 rounded-lg"
        >
          <p class="text-center inline-flex items-center justify-center">
            Thumbnail not found
          </p>
        </div>
      </router-link>
      <!-- <div v-else>
        <div
          class="w-full aspect-w-16 aspect-h-9 relative flex justify-center items-center md:rounded-lg overflow-hidden"
          v-if="video.thumbnailUrl"
        >
          <router-link :to="videoUrl">
            <img
              :src="video.thumbnailUrl"
              v-show="imgLoaded"
              alt="Video Thumbnail"
              class="h-full object-cover cursor-pointer"
              @load="imgLoaded = true"
            />
            <div
              v-show="!imgLoaded"
              class="absolute inset-0 flex items-center justify-center bg-zinc-300 dark:bg-zinc-800 animate-pulse"
            ></div>
            <div
              v-if="video.videoDuration > 0"
              class="flex items-center justify-center video-length-label"
            >
              <p class="text-white text-xs bg-black/75">
                {{ formattedDuration }}
              </p>
            </div>
          </router-link>
        </div>
        <div
          v-else
          class="w-full aspect-w-16 aspect-h-9 flex items-center justify-center bg-gray-300 dark:bg-gray-800 rounded-lg"
        >
          <p class="text-center inline-flex items-center justify-center">
            Thumbnail not found
          </p>
        </div>
      </div> -->
    </SensitivityOverlay>
    <!-- Video Information -->
    <div
      class="flex items-start mt-2 space-x-2 video-information relative px-2 md:p-0"
    >
      <router-link :to="channelUrl" class="size-7 shrink-0">
        <img
          :src="video.authorProfilePicture"
          alt="Author"
          class="rounded-full size-7 aspect-square object-cover cursor-pointer"
          onerror="this.src='/static/user-icon.png';"
        />
      </router-link>
      <div class="flex-grow overflow-hidden">
        <router-link :to="videoUrl">
          <div class="flex">
            <h3 class="line-clamp-2 overflow-hidden">{{ video.title }}</h3>
          </div>
        </router-link>
        <router-link :to="channelUrl">
          <p
            class="line-clamp-1 my-0 font-normal text-ellipsis overflow-hidden mr-5"
          >
            {{ video.authorName }}
          </p>
        </router-link>
        <p class="line-clamp-1 my-0 font-normal flex space-x-[7px]">
          <span>{{ timeAgoMessage }}</span
          ><span>•</span><span>{{ formattedViews }}</span>
        </p>
      </div>

      <CustomDropdown
        v-if="isOwnChannel"
        :options="dropdownDropdownOptions"
        placeholder=""
        @change="handleDropdownAction"
        mini="true"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from "vue";
import { timeAgo } from "@/helpers/timeAgo";
import { useModal } from "vue-final-modal";
import ConfirmDeleteVideoModal from "@/components/videoPreview/ConfirmDeleteVideoModal.vue";
import { useVideoStore } from "@/stores/videoStore";
import { useUserStore } from "@/stores/userStore";
import { useRouter } from "vue-router";
import CustomDropdown from "../customDropdown/CustomDropdown.vue";
import SensitivityOverlay from "../SensitivityOverlay.vue";

const router = useRouter();

const props = defineProps({
  video: Object,
  isOwnChannel: Boolean,
});

const formattedViews = computed(() => {
  const number = props.video.views;

  if (number === undefined) {
    return "";
  }

  if (number === 1) {
    return "1 view";
  }

  if (number >= 1000000) {
    return (number / 1000000).toFixed(1) + "M views";
  } else if (number >= 1000) {
    return (number / 1000).toFixed(1) + "K views ";
  } else {
    return number.toString() + " views";
  }
});

const dropdownDropdownOptions = [
  { label: "Edit", value: "edit" },
  { label: "Delete", value: "delete" },
];

const handleDropdownAction = (action) => {
  switch (action) {
    case "edit":
      router.push(`/v/${props.video.id}/edit`);
      console.log("edit action selected");
      break;
    case "delete":
      showDeleteVideoModal();
      console.log("delete action selected");
      break;
    default:
      console.log("No action selected");
  }
};

const emit = defineEmits(["deleteVideo"]);

const imgLoaded = ref(false);

const deleteVideo = () => {
  emit("deleteVideo", props.video.id);
};

const { open, close } = useModal({
  component: ConfirmDeleteVideoModal,

  attrs: {
    video: props.video,
    onClose() {
      close();
    },
    onDeleteVideo() {
      deleteVideo();
    },
  },
});
const showDeleteVideoModal = () => {
  toggleDropdown();
  open();
};

// const dropdownVisible = ref(false);

const videoStore = useVideoStore();
const userStore = useUserStore();

// Get the last watched time and calculate the percentage of the video watched
const lastWatchedTime = computed(() => {
  // Ensure userStore.userId exists and that the entry for userStore.userId is initialized
  if (
    videoStore.lastWatchedTime[userStore.userId] &&
    videoStore.lastWatchedTime[userStore.userId][props.video.id]
  ) {
    return Math.ceil(
      videoStore.lastWatchedTime[userStore.userId][props.video.id]
    );
  }
  // If not available, default to 0
  return 0;
});

const watchedPercentage = computed(() => {
  if (props.video.videoDuration > 0) {
    return (lastWatchedTime.value / props.video.videoDuration) * 100;
  }
  return 0;
});

const formattedDuration = computed(() => {
  const minutes = Math.floor(props.video.videoDuration / 60);
  const seconds = props.video.videoDuration % 60;
  return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
});

let timeAgoMessage = ref(timeAgo(new Date(props.video.publicationDate)));

const videoUrl = `/v/${props.video.id}?from=feed`;

const dropdownVisible = ref(false);
const toggleDropdown = () => {
  dropdownVisible.value = !dropdownVisible.value;
};

const channelUrl = computed(() => {
  return `/c/${encodeURIComponent(props.video.username)}`;
});
</script>

<style scoped lang="scss">
h3 {
  margin-bottom: 0;
  color: var(--primary);
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
}

.video-preview {
  font-size: 12px;
  padding-top: 0;
  margin-bottom: 0;
}

.video-length-label {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 10;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;

  p {
    padding: 0.15em 0.5em;
    border-radius: 6px;
    margin-bottom: 0.5em;
    margin-right: 0.5em;
    font-size: 12px;
  }
}

.video-information {
  color: var(--dim-primary);

  a,
  p {
    color: inherit;
  }
}

svg path {
  fill: var(--primary);
}

.content-container.blur-content::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  border-radius: 0.5rem;
  /* For Safari */
  z-index: 5;
  /* Below the interstitial overlay */
}

.interstitial-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 101%;
  height: 101%;
  background: linear-gradient(to right, var(red), var(blue));
  border-radius: 0.5rem;
  backdrop-filter: blur(20px);
  /* Apply blur to the overlay */
  -webkit-backdrop-filter: blur(20px);
  /* For Safari */
  z-index: 10;
  /* Under the content */
  /* Adjust opacity as needed */
}

.interstitial-content {
  position: absolute;
  /* Positioned in relation to the viewport */
  top: 50%;
  /* Center vertically */
  left: 50%;
  /* Center horizontally */
  border-radius: 0.5rem;
  transform: translate(-50%, -50%);
  /* Adjust width to fit content */
  text-align: center;
  justify-content: center;
  /* Adjust font size as needed */
  z-index: 15;
  /* Above the overlay */
  color: white;
  /* Font color */
}

.interstitial-content button {
  margin: auto;
  padding: 2px 20px;
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.4);
  color: white;
  cursor: pointer;
  transition: background 0.3s;
}

.show {
  background: linear-gradient(to right, var(red), var(blue));
}
</style>
