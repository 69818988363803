<template>
  <VueFinalModal class="modal">
    <svg
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M42.1875 9.375H34.375V7.8125C34.375 6.5693 33.8811 5.37701 33.0021 4.49794C32.123 3.61886 30.9307 3.125 29.6875 3.125H20.3125C19.0693 3.125 17.877 3.61886 16.9979 4.49794C16.1189 5.37701 15.625 6.5693 15.625 7.8125V9.375H7.8125C7.3981 9.375 7.00067 9.53962 6.70765 9.83265C6.41462 10.1257 6.25 10.5231 6.25 10.9375C6.25 11.3519 6.41462 11.7493 6.70765 12.0424C7.00067 12.3354 7.3981 12.5 7.8125 12.5H9.375V40.625C9.375 41.4538 9.70424 42.2487 10.2903 42.8347C10.8763 43.4208 11.6712 43.75 12.5 43.75H37.5C38.3288 43.75 39.1237 43.4208 39.7097 42.8347C40.2958 42.2487 40.625 41.4538 40.625 40.625V12.5H42.1875C42.6019 12.5 42.9993 12.3354 43.2924 12.0424C43.5854 11.7493 43.75 11.3519 43.75 10.9375C43.75 10.5231 43.5854 10.1257 43.2924 9.83265C42.9993 9.53962 42.6019 9.375 42.1875 9.375ZM18.75 7.8125C18.75 7.3981 18.9146 7.00067 19.2076 6.70765C19.5007 6.41462 19.8981 6.25 20.3125 6.25H29.6875C30.1019 6.25 30.4993 6.41462 30.7924 6.70765C31.0854 7.00067 31.25 7.3981 31.25 7.8125V9.375H18.75V7.8125ZM37.5 40.625H12.5V12.5H37.5V40.625ZM21.875 20.3125V32.8125C21.875 33.2269 21.7104 33.6243 21.4174 33.9174C21.1243 34.2104 20.7269 34.375 20.3125 34.375C19.8981 34.375 19.5007 34.2104 19.2076 33.9174C18.9146 33.6243 18.75 33.2269 18.75 32.8125V20.3125C18.75 19.8981 18.9146 19.5007 19.2076 19.2076C19.5007 18.9146 19.8981 18.75 20.3125 18.75C20.7269 18.75 21.1243 18.9146 21.4174 19.2076C21.7104 19.5007 21.875 19.8981 21.875 20.3125ZM31.25 20.3125V32.8125C31.25 33.2269 31.0854 33.6243 30.7924 33.9174C30.4993 34.2104 30.1019 34.375 29.6875 34.375C29.2731 34.375 28.8757 34.2104 28.5826 33.9174C28.2896 33.6243 28.125 33.2269 28.125 32.8125V20.3125C28.125 19.8981 28.2896 19.5007 28.5826 19.2076C28.8757 18.9146 29.2731 18.75 29.6875 18.75C30.1019 18.75 30.4993 18.9146 30.7924 19.2076C31.0854 19.5007 31.25 19.8981 31.25 20.3125Z"
        fill="#E50038"
      />
    </svg>
    <h1>Are you sure?</h1>
    <p>
      Are you sure you want to delete <strong>{{ video.title }}</strong
      >? This cannot be undone.
    </p>
    <div class="btn-group">
      <button class="btn btn-primary" @click="deleteVideo">Delete</button>
      <button class="btn btn-tertiary" @click="close">Close</button>
    </div>
  </VueFinalModal>
</template>
<script setup>
import { VueFinalModal } from "vue-final-modal";
import { apiBackendAuthAxios } from "@/axiosAuth.js";

const emit = defineEmits(["close", "deleteVideo"]);

const { video } = defineProps(["video"]);
const close = () => {
  emit("close");
};

const deleteVideo = () => {
  apiBackendAuthAxios.delete(`/posts/delete`, {
    data: {
      ulid: video.id,
    },
  });
  emit("deleteVideo");
};
</script>
