<template>
  <div class="w-full h-auto box-border relative">
    <!-- Interstitial Overlay -->
    <button
      v-if="isSensitive && showSensitiveContent"
      @click.stop.prevent="toggleSensitiveContent"
      title="Hide Sensitive Content"
      class="cursor-pointer select-none absolute top-1 right-1 rounded-full show z-50 bg-white dark:bg-black flex justify-center items-center"
      :class="'size-8'"
    >
      <svg
        v-if="isSensitive"
        width="24px"
        height="24px"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M20.707 20.707a1 1 0 0 0 0-1.414l-16-16a1 1 0 0 0-1.414 1.414L5.205 6.62C2.785 8.338 1.5 10.683 1.5 12c0 2.25 3.75 7.5 10.5 7.5 1.916 0 3.59-.423 5.006-1.08l2.287 2.287a1 1 0 0 0 1.414 0zm-6.13-4.716-1.51-1.51a2.7 2.7 0 0 1-3.548-3.548l-1.51-1.51a4.75 4.75 0 0 0 6.568 6.568zM22.5 12c0 1.005-.749 2.61-2.18 4.078l-3.594-3.595a4.75 4.75 0 0 0-5.209-5.209L9.088 4.846C9.985 4.626 10.957 4.5 12 4.5c6.75 0 10.5 5.25 10.5 7.5z"
          fill="#000000"
        />
      </svg>
    </button>
    <div
      class="h-auto w-full"
      v-if="isSensitive && !showSensitiveContent"
    >
      <div
        class="interstitial-overlay opacity-40 md:opacity-100"
      >
        <!-- Empty div for the overlay background -->
      </div>
      <!-- Interstitial Content -->
      <div>
        <!-- Interstitial Content -->
        <div class="interstitial-content w-full h-auto">
          <div class="flex-col justify-around space-y-1 space-x-4">
            <h1
              class="font-bold text-lg [text-shadow:_3px_3px_3px_rgb(0_0_0_/_0.3)]"
            >
              Sensitive Content
            </h1>
            <!-- Display the button to view content only if not in the native PWA wrapper -->
            <button
              v-if="isSensitive"
              @click="toggleSensitiveContent"
              class="cursor-pointer select-none [text-shadow:_3px_3px_3px_rgb(0_0_0_/_0.3)]"
            >
              View Anyways
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
      class="content-container"
      :class="{
        'blur-content': isSensitive && !showSensitiveContent,
      }"
    ></div>
    <slot></slot>
  </div>
</template>

<script setup>
import { computed, ref } from "vue";
import { useVideoStore } from "@/stores/videoStore";

// Props for sensitivity flags
const props = defineProps({
  isSensitive: Boolean,
  videoId: String,
});

const videoStore = useVideoStore();

console.log(props.videoId);
// Internal state for toggling sensitive content visibility
const defaultShowSensitiveContent = computed(() => {
  if (videoStore.showSensitiveContent[props.videoId] === true) {
    return videoStore.showSensitiveContent[props.videoId];
  } else {
    return false;
  }
});

const showSensitiveContent = ref(defaultShowSensitiveContent.value);

// Toggle the visibility of sensitive content
const toggleSensitiveContent = () => {
  showSensitiveContent.value = !showSensitiveContent.value;
  videoStore.updateSensitiveContent(props.videoId, showSensitiveContent.value);
};
</script>

<style scoped>
.content-container.blur-content::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  border-radius: 0.5rem;
  /* For Safari */
  z-index: 5;
  /* Below the interstitial overlay */
}

.interstitial-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, var(red), var(blue));
  border-radius: 0.5rem;
  backdrop-filter: blur(20px);
  /* Apply blur to the overlay */
  -webkit-backdrop-filter: blur(20px);
  /* For Safari */
  z-index: 10;
  /* Under the content */
  /* Adjust opacity as needed */
}

.interstitial-content {
  position: absolute;
  /* Positioned in relation to the viewport */
  top: 50%;
  /* Center vertically */
  left: 50%;
  /* Center horizontally */
  border-radius: 0.5rem;
  transform: translate(-50%, -50%);
  /* Adjust width to fit content */
  text-align: center;
  justify-content: center;
  /* Adjust font size as needed */
  z-index: 15;
  /* Above the overlay */
  color: white;
  /* Font color */
}

.interstitial-content button {
  margin: auto;
  padding: 2px 20px;
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.4);
  color: white;
  cursor: pointer;
  transition: background 0.3s;
}

.show {
  background: linear-gradient(to right, var(red), var(blue));
}
</style>
