<template>
  <div class="max-w-[4367px] flex-col mx-auto md:px-16">
    <!--
    <div class="flex justify-start items-center space-x-2 px-4 pt-4">
      <a href="#recently-added-videos">
        <DashboardPill content="Recent" />
      </a>
      <a href="#trending-videos">
        <DashboardPill content="Trending" />
      </a>
    </div>
    -->
    <TrendingVideoSection
      :loading="loading"
      @loaded-videos="loadedTrendingVideos"
      id="trending-videos"
    />
    <RecentlyAddedVideoSection
      :loading="loading"
      @loaded-videos="loadedRecentlyAddedVideos"
      id="recently-added-videos"
      :onDashboard="true"
    />
  </div>
</template>
<script setup>
import RecentlyAddedVideoSection from "@/views/dashboard/recentlyAdded/RecentlyAddedVideoSection.vue";
import { computed, ref } from "vue";
import TrendingVideoSection from "@/views/dashboard/trending/TrendingVideoSection.vue";
// import DashboardPill from "@/views/dashboard/DashboardPill.vue";

const loadingRecentlyAddedVideo = ref(true);
const loadingTrendingVideos = ref(true);
import mixpanel from "mixpanel-browser";

mixpanel.track("Dashboard Page");
const loading = computed(() => {
  return loadingRecentlyAddedVideo.value || loadingTrendingVideos.value;
});
const loadedRecentlyAddedVideos = () => {
  loadingRecentlyAddedVideo.value = false;
};

const loadedTrendingVideos = () => {
  loadingTrendingVideos.value = false;
};
</script>
