<template>
  <div class="menu">
    <div class="flex justify-between items-center gap-4 dark-theme-toggle">
      <span>Dark theme</span>
      <span class="toggle">
        <label class="switch">
          <input type="checkbox" v-model="darkMode" @click="toggleDarkMode" />
          <span class="slider round"></span>
        </label>
      </span>
    </div>
  </div>
  <LoadingCover v-if="loading" title="Updating mode settings" />
</template>
<script setup>
import { useDarkModeStore } from "@/stores/darkModeStore.tsx";
import { computed, ref } from "vue";
import { useAuthStore } from "@/stores/auth.tsx";
import LoadingCover from "@/components/loading/LoadingCover.vue";
import { apiBackendAuthAxios } from "@/axiosAuth.js";
import { useUserStore } from "@/stores/userStore.tsx";

const authStore = useAuthStore();
const darkModeStore = useDarkModeStore();
const userStore = useUserStore();

const loading = ref(false);

const darkMode = computed(() => {
  return darkModeStore.darkMode;
});

const toggleDarkMode = async () => {
  if (!authStore.authenticated) {
    if (darkMode.value) {
      darkModeStore.setLightMode();
    } else {
      darkModeStore.setDarkMode();
    }
    return;
  }

  try {
    loading.value = true;
    await apiBackendAuthAxios.patch(
      `/user/settings?dark_mode=${darkMode.value ? 0 : 1}`
    );

    await userStore.refreshUserState();
  } catch (e) {
    console.error(e);
  } finally {
    loading.value = false;
  }
};
</script>
